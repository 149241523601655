<template>
  <v-container fluid>
    <v-layout row>
      <v-flex md12>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Allocate Practical Center</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-layout row class="py-1 mx-1">
            <v-flex md6>
              <v-autocomplete
                v-if="getLoggedInDegree.degree_code != 1"
                class="ma-2"
                v-model="requestData.subject_code"
                :item-text="
                  item => `${item.subject_code} - ${item.subject_name}`
                "
                item-value="subject_code"
                :items="getSubject"
                menu-props="auto"
                label="Select Subject"
                prepend-icon="subject"
                v-validate="'required'"
                :error-messages="errors.collect('subject')"
                data-vv-name="subject"
                required
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>

          <v-layout
            row
            v-for="(cen_cols, index) in requestData.centers"
            :key="index"
          >
            <v-flex class="mx-1 ma-1 my-1 mt-3">
              <v-btn fab small color="red darken-3 white--text" disabled>
                {{ index + 1 }}
              </v-btn>
            </v-flex>
            <v-flex md8>
              <v-autocomplete
                v-if="getLoggedInDegree.degree_code != 1"
                class="ma-2"
                v-model="cen_cols.center_college_code"
                :item-text="
                  item => `${item.college_code} - ${item.college_name}`
                "
                item-value="college_code"
                :items="getAllCollegeList"
                menu-props="auto"
                label="Select Center"
                prepend-icon="subject"
                v-validate="'required'"
                :error-messages="errors.collect('center')"
                data-vv-name="center"
                required
                clearable
              >
              </v-autocomplete>
            </v-flex>
            <v-flex md8>
              <v-autocomplete
                v-if="getLoggedInDegree.degree_code != 1"
                class="ma-2"
                v-model="cen_cols.college_code"
                :item-text="
                  item => `${item.college_code} - ${item.college_name}`
                "
                item-value="college_code"
                :items="getFFColleges"
                menu-props="auto"
                label="Select College"
                prepend-icon="subject"
                v-validate="'required'"
                :error-messages="errors.collect('college')"
                data-vv-name="college"
                required
                clearable
              >
              </v-autocomplete>
            </v-flex>
            <v-flex md1 class="mx-1 ma-1 my-1 mt-3">
              <v-btn
                fab
                small
                color="red darken-3 white--text"
                v-if="index == 0"
                disabled
                @click="removeRow(index)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
              <v-btn
                fab
                small
                color="red darken-3 white--text"
                v-if="index !== 0"
                @click="removeRow(index)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-btn
              fab
              class="ma-1 pa-0"
              small
              color="indigo darken-3 white--text"
              @click="addRow()"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-actions class="text-center">
            <v-spacer />
            <v-btn
              id="button_rcv"
              color="indigo white--text"
              text
              @click="validationForm"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import commonGet from "@/mixin/common_getters_mixin";
import common_login_getters from "@/mixin/common_login_getters";
export default {
  mixins: [commonGet, common_login_getters],
  $_veeValidate: {
    validator: "new"
  },
  data() {
    return {
      dialog: false,
      degDialog: false,
      requestData: {
        exam_code: "",
        exam_year: "",
        exam_initiate_id: "",
        type: "",
        subject_code: "",
        centers: [{}]
      }
    };
  },
  watch: {
    dialog(val) {
      if (!val) this.close();
    }
  },

  created() {
    this.$store.dispatch("subjectByDegree", {
      degree_code: this.getLoggedInDegree.code
    });
    this.$store.dispatch("getAllColleges");
    this.$store.dispatch("fetchExamWiseColleges", {
      exam_id: this.getExamInitiateId
    });
  },
  computed: {
    getAllCollegeList() {
      return this.$store.getters.getAllCollegeList;
    },
    getFFColleges() {
      return this.$store.getters.getSearchParamCollege;
    },
    getLoggedInExam() {
      return this.$store.getters.getLoggedInExam;
    },
    getLoggedInDegree() {
      return this.$store.getters.getLoggedInDegree;
    },
    getSubject() {
      return this.$store.getters.getSubjectData;
    },
    getExamInitiateId() {
      return this.$store.getters.getExamInitiateId;
    }
  },
  methods: {
    addRow() {
      this.requestData.centers.push({
        center_college_code: "",
        college_code: ""
      });
    },
    removeRow(index) {
      if (this.requestData.centers.length > 1) {
        this.requestData.centers.splice(index, 1);
      }
    },
    validationForm() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.submitData();
        }
      });
    },
    submitData() {
      let self = this;
      self.requestData.exam_code = self.getLoggedInExam.code;
      self.requestData.exam_year = self.getLoggedInYear.year;
      self.requestData.exam_initiate_id = self.getExamInitiateId;
      self.requestData.type = 2;
      const requestData = this.requestData;
      this.$store
        .dispatch("savePracticalCenterAllocatedData", requestData)
        .then(() => {
          try {
            self.close();
            //self.$parent.$parent.getDataFromApi();
          } catch (ex) {
            console.log(ex);
          }
        });
    },
    close() {
      this.submitStatus = false;
    }
  }
};
</script>
